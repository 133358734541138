export function getGoodsType(type) {
    let name = '';
    switch (type) {
        case 1:
            name = '物料';
            break;
        case 2:
            name = '成品';
            break;
        case 3:
            name = '半成品';
            break;
        case 4:
            name = '虚拟';
            break;
    }
    return name;
}
