import request from '@/plugins/http'

export function apiGetPlanGoodsDetail(param) {
    return request.post('/api/create/getPlanGoodsDetail', param);
}

export function apiSaveToCreateOperate(param) {
    return request.post('/api/create/saveToCreateOperate', param);
}

export function apiCloseCreatePlan(param) {
    return request.post('/api/create/closeCreatePlan', param);
}




