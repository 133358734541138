<template>
<div style="background-color: #f8f8f9;">
  <div class="print-button">
    <div>
      <Button type="info" @click="downloadData" :loading="downloadFlag">下载</Button>
      <Button type="primary" class="ml-10" @click="printData" :loading="print_loading">打印</Button>
    </div>

<!--    <div class="mt-10">-->
<!--      <Input v-model="table_line_height" />-->
<!--    </div>-->
  </div>
<!--  <div class="print-set">-->
<!--    -->
<!--  </div>-->
  <div id="a4-print">
    <div style="display:flex;">
      <div style="width:80%;" class="fs-12">
        <div class="align-center mt-10"><span class="fs-16 fw-bold ">生产用料明细</span></div>
        <div style="display:flex;">
          <div style="width:50%;">
            <span style="display:inline-block;width:80px;text-align: right;">计划单号:</span>
            <span class="ml-10" style="font-weight: 500;">{{ plan_info.main_no }}</span>
          </div>
          <div style="width:50%;">
            <span style="display:inline-block;width:80px;text-align: right;">预计完成时间:</span>
            <span class="ml-10" style="font-weight: 500;">{{plan_info.expect_finish_time.substring(0, 10)}}</span>
          </div>
        </div>
        <div style="display:flex;" class="mt-10">
          <div style="width:50%;">
            <span style="display:inline-block;width:80px;text-align: right;">货品名称:</span>
            <span class="ml-10" style="font-weight: 500;">({{goods_info.main_no}}){{goods_info.goods_name}}</span>
          </div>
          <div style="width:50%;">
            <span style="display:inline-block;width:80px;text-align: right;">计划生产数量:</span>
            <span class="ml-10" style="font-weight: 500;">{{goods_info.plan_num}}</span>
          </div>
        </div>
      </div>
      <div style="width:20%;margin-top:10px;">
        <img style="height:70px;" v-show="goods_info.goods_img" :src="goods_info.goods_img" alt="">
      </div>
    </div>

    <Divider style="padding-left: 10px;padding-right: 10px;font-size:16px;">BOM部件用料明细</Divider>
    <div class="padding-4" :style="{'--tableLineHeight':table_line_height}">
      <Table :columns="columns" :data="goods_set" border>
        <template slot-scope="{ row }" slot="key">
          <span :class="row.children_flag?'status-warning':''">{{ row.key }}</span>
        </template>

        <template slot-scope="{ row }" slot="goods_type">
          <span>{{ getGoodsType(row.goods_type) }}</span>
        </template>
      </Table>
    </div>
  </div>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import printJS from 'print-js'

import NP, {times} from 'number-precision';
import {getGoodsType} from "@/utils/common";

import {apiGetPlanGoodsDetail} from '@/api/create/createPlan';


export default {
  name: "CreateProjectDetailView",
  data() {
    return {
      downloadFlag: false,
      print_loading: false,

      table_line_height: '35px',
      goods_img: "",
      goods_set: [],
      plan_info: {
        main_no: '',
        expect_finish_time: ''
      },
      goods_info: {
        main_no:'',
        goods_name:'',
        goods_img:'',
        plan_num:'',
      },
      columns: [
        {
          title: '序号',
          key: 'key',
          slot: 'key',
          width: 40,
          align: 'center',
        },
        {
          title: '编号',
          key: 'part_no',
          width: 80,
          align: 'center',
        },
        {
          title: '货品名称',
          key: 'part_name',
          align: 'center',
        },
        {
          title: '类型',
          key: 'goods_type',
          slot: 'goods_type',
          width: 60,
          align: 'center',
        },
        {
          title: '规格',
          key: 'specification',
          align: 'center',
        },
        {
          title: '单用量',
          key: 'single_num',
          width: 50,
          align: 'center',
        },
        {
          title: '用量',
          key: 'all_num',
          align: 'center',
        },
        {
          title: '单位',
          key: 'unit',
          width:  40,
          align: 'center',
        },
        {
          title: '备注',
          key: 'remark',
          align: 'center',
        },
      ],
    }
  },
  mounted() {
    this.getBaseInfo();
  },
  methods: {
    getGoodsType(type) {
      return getGoodsType(type);
    },
    getBaseInfo() {
      //获取计划生产单明细
      const {plan_id, goods_id} = this.$route.query
      let param = {
        plan_id: plan_id,
        goods_id: goods_id
      }
      apiGetPlanGoodsDetail(param).then(res => {
        if (res.data.err_no == 0) {
          const result = res.data.results;
          //plan info
          this.plan_info.main_no = result.planInfo.main_no;
          this.plan_info.expect_finish_time = result.planInfo.expect_finish_time;

          //goods info
          this.goods_info.main_no = result.goodsInfo.part_no;
          this.goods_info.goods_name = result.goodsInfo.part_name;
          this.goods_info.plan_num = result.goodsInfo.plan_num;
          if (result.goodsInfo.image_url.length>0 && result.goodsInfo.image_url[0].url != undefined) {
            this.getBase64Image(result.goodsInfo.image_url[0].url)
                .then(base64Image => {
                  // 成功获取到base64编码
                  this.goods_info.goods_img = base64Image;
                }).catch(error => {
              // 获取base64编码失败，处理错误信息
            });
          } else {
            this.goods_info.goods_img = '';
          }

          this.getBomDetail(result.bomDetail.children, this.goods_info.plan_num);
        }
      });

    },
    getBomDetail(bom, plan_num, main_no = 0) {
      for (let i=0; i<bom.length; i++) {
        let tmp = {
          key: main_no ? (main_no.toString()+'-'+ (i + 1).toString()):(i+1),
          children_flag: main_no != 0 ? true : false,
          part_no: bom[i].part_no,
          part_name: bom[i].part_name,
          goods_type: bom[i].goods_type,
          specification: bom[i].specification,
          single_num: bom[i].number,
          all_num: NP.times(bom[i].number, plan_num),
          unit: bom[i].unit,
          remark: bom[i].remark,
        }
        this.goods_set.push(tmp);

        if (bom[i].children.length > 0) {
          this.getBomDetail(bom[i].children, tmp.all_num, i + 1);
        }
      }
    },
    printData() {
      this.print_loading = true;
      // 获取要转换为PDF的HTML元素
      const element = document.getElementById('a4-print');

      const options = {
        margin: [0, 0, 0, 0],
        image: {type: 'png', quality: 0.98},
        html2canvas: {scale: 4},
        jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'},
        pagebreak: {  avoid: ['.ivu-table-row'] }
      };

      // 将HTML转换为PDF
      html2pdf().set(options).from(element).outputPdf('blob').then(blob => {
        printJS(window.URL.createObjectURL(new Blob([blob],{type: 'application/pdf'})));
        setTimeout(() => {this.print_loading = false;}, 2000 );
      });
    },
    downloadData() {
      this.downloadFlag = true;
      // 获取要转换为PDF的HTML元素
      const element = document.getElementById('a4-print');

      const options = {
        margin: [0, 0, 0, 0],
        image: {type: 'png', quality: 0.98},
        html2canvas: {scale: 4},
        jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'},
        pagebreak: {  avoid: ['.ivu-table-row'] }
      };

      // 将HTML转换为PDF
      html2pdf().set(options).from(element).save(this.plan_info.main_no+'('+this.goods_info.goods_name+')'+'计划生产明细.pdf').then(() => {
        this.downloadFlag = false
      });
    },
    getBase64Image(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url+"?timeStamp="+new Date();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0);
          let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase(); // 获取到图片的格式
          const dataURL = canvas.toDataURL("image/" + ext); // 得到base64 编码的 dataURL
          resolve(dataURL);
        };
        img.onerror = function () {
          reject(new Error("图片加载失败"));
        };
      });
    },
  }
}
</script>

<style scoped>
#a4-print {
  width: 210mm;
  min-height: 290mm;
  padding: 0;
  margin: 0 auto;
  background-color: #fff;
}

.print-button {
  position: absolute;
  right: 80px;
  padding-top: 20px;
}

.print-set{
  position: absolute;
  right: 80px;
  padding-top: 80px;
}


</style>
<style>
#a4-print .ivu-table {
  font-size: 12px !important;
}

#a4-print .ivu-table td, .ivu-table th {
  height: var(--tableLineHeight, 40px);
}

#a4-print .ivu-table-cell {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
</style>
